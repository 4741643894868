import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import $ from "jquery"
import React, { useEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import TextTruncate from "react-text-truncate"; // recommend
import TruncateMarkup from "react-truncate-markup"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Default = (  ) => {
  const pageQuery = useStaticQuery(graphql`
    query homeQuery {
      allWpTestimonial(sort: { fields: [date], order: DESC }) {
        nodes {
          title
          uri
          content
          slug
          testimonials {
            location
          }
        }
      }
      allWpPost(sort: { fields: [date], order: DESC }, limit: 8) {
        nodes {
          id
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          blog {
            thumbnail {
              description
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          seo {
            metaDesc
            title
          }
          tags {
            nodes {
              id
              name
              uri
            }
          }
        }
      }
    }
  `)
  const testimonials = pageQuery.allWpTestimonial.nodes
  const news = pageQuery.allWpPost.nodes

  const [bodyClasses, setBodyClasses] = useState("homepage")

  const swipeRelPost = useRef(null)

  useEffect(() => {
    $(".swiper-button-prev").on("click", () => {
      swipeRelPost.current.slidePrev()
    })
    $(".swiper-button-next").on("click", () => {
      swipeRelPost.current.slideNext()
    })
  }, [])

  return (
    <div>
      <Seo
        title={
          pageQuery.seo?.title
            ? pageQuery.seo.title
            : "ARC: The Expert Recruiters in Medical Aesthetics"
        }
        description={
          pageQuery.seo?.metaDesc
            ? pageQuery.seo?.metaDesc
            : "Get in touch with ARC, the UK's leading aesthetic medicine recruiters, to source staff or find work in medical aesthetics."
        }
      />

      <Helmet
        bodyAttributes={{
          class: bodyClasses,
        }}
      />

      <Layout>
        <section className="header_section">
          <div className="container">
            <div className="header_section__content">
              <h1>
                Refined <br />
                Recruitment
              </h1>
              <div className="header_section__content_sub">
                <p>by </p>
                <div>The Expert Recruiters in Medical Aesthetics </div>
              </div>
              <div className="header_section__content_btn">
                <a href="/jobs/" className="waves-effect waves-dark btn">
                  FIND WORK
                </a>
                <a href="/services/" className="waves-effect waves-light btn">
                  FIND STAFF
                </a>
              </div>
            </div>
            <div className="header_section__image">
              <div className="header-container">
                <div className="swiper-wrapper">
                  <StaticImage
                    className="swiper-slide"
                    src="../../static/assets/images/header/image_1.svg"
                    placeholder="blurred"
                    alt="Imgage 01"
                  />
                  <StaticImage
                    className="swiper-slide"
                    src="../../static/assets/images/header/image_2.svg"
                    placeholder="blurred"
                    alt="Imgage 02"
                  />
                  <StaticImage
                    className="swiper-slide"
                    src="../../static/assets/images/header/image_3.svg"
                    placeholder="blurred"
                    alt="Imgage 03"
                  />
                  <StaticImage
                    className="swiper-slide"
                    src="../../static/assets/images/header/image_4.svg"
                    placeholder="blurred"
                    alt="Imgage 04"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="home_header_path">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 1920 206"
              preserveAspectRatio="none"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M.5,1210.979h1924V1038.933s-686.837,44.2-1038.956-54.966S.5,1037.8.5,1037.8Z"
                transform="translate(-0.5 -950.53)"
                fill="#ecebe4"
              />
            </svg>
          </div>
        </section>

        <section className="intro_boxes">
          <div className="container-fluid">
            <div className="intro_boxes_holder">
              <div className="intro_boxes__box card">
                <div className="intro_boxes__box_title underline_heading">
                  <span>Staff Your Aesthetics Team</span>
                </div>
                <div className="intro_boxes__box_desc">
                  <p>
                    We have the expertise to solve industry-specific staffing
                    problems, and source exceptional employees. We are a trusted
                    recruitment partner to clinics, and product and equipment
                    suppliers.
                  </p>
                </div>
                <div className="intro_boxes__box_link">
                  <a href="/services/">
                    Read More <span></span>
                  </a>
                </div>
                <div className="intro_boxes__box_icon">
                  <img src="/assets/images/icons/staff.svg" alt="" />
                </div>
              </div>
              <div className="intro_boxes__box card text-right">
                <div className="intro_boxes__box_title underline_heading putty text-right">
                  <span>Work in Medical Aesthetics</span>
                </div>
                <div className="intro_boxes__box_desc">
                  <p>
                    We’re career consultants for aesthetic professionals. We can
                    provide industry insights and careers advice to help you
                    plot your path. Get in touch and we’ll find the perfect role
                    to match your work and life goals.
                  </p>
                </div>
                <div className="intro_boxes__box_link">
                  <a href="/jobs/">
                    Read More <span></span>
                  </a>
                </div>
                <div className="intro_boxes__box_icon">
                  <img src="/assets/images/icons/needle.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="speak_to">
          <div className="container">
            <div className="speak_to__box">
              <div className="speak_to__box_left">
                <div className="speak_to__box_title">Speak to Our Team </div>
                <div className="speak_to__box_desc">
                  Discuss your hiring needs or job search.
                </div>
              </div>
              <div className="speak_to__box_right">
                <div className="speak_to__box_number">
                  {" "}
                  <a href="tel:01273823571">01273 823571</a>
                </div>
              </div>
              <div className="speak_to__box_icon">
                <img src="/assets/images/icons/call.svg" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="business_advice">
          <div className="container">
            <div className="business_advice__title underline_heading putty">
              <span>Aesthetics Careers and Business Advice</span>
            </div>

            <div className="business_advice__slides">
              <div className="swiper-wrapper">
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  ref={swipeRelPost}
                  onSwiper={swiper => (swipeRelPost.current = swiper)}
                >
                  {news.map((post, i) => {
                    //const image = post.blog.thumbnail.sourceUrl
                    const image = getImage(post.blog.thumbnail.localFile)

                    // const date = new Date(post.date)
                    // const year = date.getFullYear().toString().substr(-2)
                    // const month = (date.getMonth() + 1).toLocaleString(
                    //   "en-UK",
                    //   {
                    //     minimumIntegerDigits: 2,
                    //     useGrouping: false,
                    //   }
                    // )
                    // const day = date.getDate().toLocaleString("en-UK", {
                    //   minimumIntegerDigits: 2,
                    //   useGrouping: false,
                    // })
                    return (
                      <SwiperSlide key={post.id} virtualIndex={i}>
                        <div
                          className="business_advice__slides_slide matchHeight"
                          key={post.id}
                        >
                          <div className="business_advice__slides_slide_img">
                            <a href={post.uri}>
                              <GatsbyImage
                                image={image}
                                placeholder="blurred"
                                alt={post.title}
                              />
                            </a>
                          </div>
                          <div className="business_advice__slides_slide_content">
                            <div className="business_advice__slides_slide_title">
                              <TextTruncate
                                line={3}
                                element="span"
                                truncateText="…"
                                text={post.title}
                              />
                            </div>
                            <div className="business_advice__slides_slide_text">
                              <TruncateMarkup lines={4} lineHeight={91}>
                                <div>{ReactHtmlParser(post.excerpt)}</div>
                              </TruncateMarkup>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>

              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </section>

        <section className="home_testimonials">
          <div className="container">
            <div className="home_testimonials__title">Testimonials</div>
            <div className="home_testimonials__holder testimonial-container">
              <div className="swiper-wrapper">
                {testimonials.map(post => {
                  return (
                    <div
                      className="home_testimonials__slide swiper-slide"
                      key={post.uri}
                    >
                      <div className="home_testimonials__slide_text">
                        <div
                          dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                      </div>
                      <div className="home_testimonials__slide_author">
                        <span className="home_testimonials__slide_author_location">
                          {post.title}
                        </span>
                        <span className="home_testimonials__slide_author_location">
                          {post.testimonials.location}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="testimonial-pagination text-center"></div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Default;
